<template>
  <div class="home-page">
    
  </div>
</template>

<script>
/* global $ */
import { Notification } from "element-ui";
import moment from "moment";
export default {
  data() {
    return {
      allOverdueList: [],
      myTodayList: [],
      myOverdueList: [],
      myTodoList: [],

      //回報modal要用的
      sbModal: {
        SprintBacklogsReturn: {},
      },

      feedbackList: [],
      invalidWorkMemoList: [],
      returnLevelList:[{
          value: "規格"
        },{
          value: "開發"
        },{
          value: "測試"
        }]
    };
  },
  methods: {
    getWorkList() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/TaskInfomation/FrontPage`;
      
      const allOverdueParams = {
        UserName: this.$cookies.get("userName"),
        StageType: 0,
        
      };
     
      const myTodayParams = {
        UserName: this.$cookies.get("userName"),
        StageType: 1,
      };
      const myOverdueParams = {
        UserName: this.$cookies.get("userName"),
        StageType: 2,
      };
      const myTodoParams = {
        UserName: this.$cookies.get("userName"),
        StageType: 3,
      };
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, allOverdueParams)
        .then((response) => {
          vm.$store.dispatch("updateLoading", false);
          
          vm.allOverdueList = response.data;
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });

      vm.$http
        .post(api, myTodayParams)
        .then((response) => {
          vm.$store.dispatch("updateLoading", false);
          
          vm.myTodayList = response.data;
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });

      vm.$http
        .post(api, myOverdueParams)
        .then((response) => {
          vm.$store.dispatch("updateLoading", false);
          
          vm.myOverdueList = response.data;
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });

      vm.$http
        .post(api, myTodoParams)
        .then((response) => {
          vm.$store.dispatch("updateLoading", false);
          
          vm.myTodoList = response.data;
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    //取得回報列表
    getSprintBacklogsReturn(sbNum) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/TaskInfomation/SprintBacklogsReturn/${sbNum}`;
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          vm.$store.dispatch("updateLoading", false);
          
          vm.feedbackList = response.data;
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    //開啟modal
     openTaskDetailModal(task) {
      const vm = this;
      vm.sbModal = {
        ...task,
        Repair: false,
        SprintBacklogsReturn: {
          ReturnDate: moment().format("YYYY-MM-DD"),
          ReturnWork: 0,
          ReturnMemo: "",
          IsReturn:"",
          WorkType:"",
          ReturnUser: [
            {
              Account: vm.$cookies.get("userAccount"),
              Name: vm.$cookies.get("userName"),
            },
          ],
        },
      };

      vm.getSprintBacklogsReturn(task.SbNum);
      $("#taskDetailModal").modal("show");
    },

    //送出回報
    sendFeedback() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/TaskInfomation/SprintBacklogsReturn`;

      
      const params = {        
        SbNum: vm.sbModal.SbNum,       
        SbRealBdate: vm.sbModal.SbRealBdate,
        SbRealEdate: vm.sbModal.SbRealEdate,        
        SprintBacklogsReturn: {
          ...vm.sbModal.SprintBacklogsReturn,
          SbLv:vm.sbModal.SbLv  //為了記錄目前回報時是什麼階段 把外層的Sblv丟進來送出去
          },    
        ReturnType:vm.sbModal.ReturnType
      };

      
      // 如果返修的話 SbLv要變成選擇的SprintBacklogsReturn.ReturnType
       if(vm.sbModal.SprintBacklogsReturn.IsReturn==="Y"){                  
         params.SbLv=vm.sbModal.SprintBacklogsReturn.ReturnType;        
        }else{         
          //如果不返修 SbLv維持原本的
          params.SbLv=vm.sbModal.SbLv;                
          params.SprintBacklogsReturn.ReturnType="";//避免有人選了Y選了狀態又改回N   
        }




      vm.$store.dispatch("updateLoading", true);

      

      vm.$http
        .post(api, params)
        .then(() => {
          vm.$store.dispatch("updateLoading", false);
          Notification({
              title: "成功",
              message: "回報成功",
              type: "success",
              duration: 2000,
            });
          vm.getWorkList();
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },
    
    //取得異常原因
    getInvalidWorkMemo() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/Common/InvalidWorkMemo`;

      vm.$http
        .get(api)
        .then((response) => {
          vm.invalidWorkMemoList = response.data;
        })
        .catch((error) => {
          Notification({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },
  },
  mounted() {
    $(window).scrollTop(0);
    window.onbeforeunload = function () {
      //刷新頁面後自動回到頂部
      document.documentElement.scrollTop = 0; // IE
      document.body.scrollTop = 0; // 非IE
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  created() {
    const vm = this;
    vm.getWorkList();
    this.getInvalidWorkMemo();
  },
};
</script>

<style lang="scss" scoped>
h2,
h5 {
  margin: 20px;
}
.home-page {
  height: calc( 100vh - 70px);
  background-image: url("~@/assets/img/home.jpg");
  background-size: cover;
}
</style>
